@font-face {
  font-family: "Open Sans Regular";
  src: url("../fonts/Open_Sans/OpenSans-Regular.ttf") format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans Semi Bold";
  src: url("../fonts/Open_Sans/OpenSans-SemiBold.ttf") format('truetype');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans Bold";
  src: url("../fonts/Open_Sans/OpenSans-Bold.ttf") format('truetype');
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

$font-open-sans-regular: "Open Sans Regular", serif;
$font-open-sans-semi-bold: "Open Sans Semi Bold", serif;
$font-open-sans-bold: "Open Sans Bold", serif;
