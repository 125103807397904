@import "bootstrap/bootstrap";

@import "config/colors";

@import "config/fonts";

.bckg-dark {
  background: #141515;

  color: #fafafa;
}

form {
  padding: 50px 30px;
  background: #242a42;
  border-radius: 5px;

  button.btn {
    background: #5f77ea;
    color: #fff;
    width: 100%;

    &:hover {
      color: #fff;
      background: darken(#5f77ea, 5%);
    }
  }
}

a {
  color: #38b1b7;
  text-decoration: none;

  &:hover {
    color: darken(#38b1b7, 10);
    text-decoration: none;
  }

  &:focus {
    color: #38b1b7;
    text-decoration: none;
  }
}